import * as React from 'react'
import { Link } from '../link'
import { ExternalLink } from '../icons'

interface Props {}

type ListHeaderProps = {
  subheading: string
  heading: string
  description?: string
}
type ListItemProps = {
  title: string
  slug: string
}

const Header = ({ heading, subheading, description }: ListHeaderProps) => (
  <div className="resource-list__header child-my-0">
    <p className="resource-list__header-sub type-mono-md">{subheading}</p>
    <h2 className="resource-list__header-main type-h3">{heading}</h2>
    {!description ? null : (
      <p className="resource-list__header-desc type-p-lg">{description}</p>
    )}
  </div>
)

const Item = ({ title, slug }: ListItemProps) => (
  <li>
    <Link className="resource-list__item" to={slug}>
      <span className="resource-list__item-name type-h5">{title}</span>
      <span aria-hidden className="resource-list__item-icon">
        <ExternalLink />
      </span>
    </Link>
  </li>
)

export default class ResourceList extends React.Component<Props, {}> {
  static defaultProps = {}
  static Header = Header
  static Item = Item

  public render() {
    const { children } = this.props
    return <ul className="resource-list">{children}</ul>
  }
}
