import React, { Component } from 'react'
import anime from 'animejs'
import { Waypoint } from 'react-waypoint'
import { CompanyCard } from '../card'
import styles from './our-companies.module.scss'
import { useDynamicTracking } from '../../hooks'

const [CARD_WIDTH, CARD_GAP, STACK_GAP] = [469, 18, 100]

const getTransformX = () => CARD_WIDTH + CARD_GAP
const getSlideX = n => n * getTransformX()
// const getSelectedOffsetX = () => STACK_GAP

export class Carousel extends Component {
  static defaultProps = {
    companies: [],
  }

  constructor(props) {
    super(props)

    const { companies } = this.props

    this.state = {
      items: companies,
      visible: false,
      selectedIndex: 0,
    }

    this.elements = {
      container: React.createRef(),
      slides: companies.map(() => React.createRef()),
    }
  }

  componentDidUpdate(_, prevState) {
    if (this.state.visible !== prevState.visible) {
      animateCarouselIntro(this.elements)
    }
    if (this.state.selectedIndex !== prevState.selectedIndex) {
      animateSlideChange(this.elements, this.state.selectedIndex)
    }
  }

  onPrevious = () => {
    this.setState(({ selectedIndex, items }) => {
      if (selectedIndex === 0) return { selectedIndex: items.length - 1 }
      return { selectedIndex: selectedIndex - 1 }
    })
  }

  onNext = () => {
    this.setState(({ selectedIndex, items }) => {
      if (selectedIndex === items.length - 1) return { selectedIndex: 0 }
      return { selectedIndex: selectedIndex + 1 }
    })
  }

  onClickToAdvance = e => {
    const idx = this.elements.slides
      .map(ref => ref.current)
      .indexOf(e.currentTarget)
    this.setState(({ selectedIndex }) => {
      if (selectedIndex === idx) return
      return { selectedIndex: idx }
    })
  }

  render() {
    const { items } = this.state
    const [onTrack] = useDynamicTracking({
      action: 'Clicked Company in Carousel',
      category: 'Home Page',
    })
    return (
      <div className={styles.container}>
        <Waypoint onEnter={() => this.setState({ visible: true })} />
        <div className={styles.overflow}>
          <div className="container container--grid">
            <ul ref={this.elements.container} className={styles.carousel}>
              {items.map((item, idx) => (
                <li
                  key={item.name}
                  ref={this.elements.slides[idx]}
                  className={styles.card}
                  onClick={this.onClickToAdvance}
                >
                  <CompanyCard
                    company={item}
                    onClick={() => onTrack({ label: item.name })}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

function animateCarouselIntro({ slides }, complete) {
  const [first, ...rest] = slides.map(s => s.current)
  anime({
    targets: first,
    opacity: 1,
    translateY: [100, -24],
    duration: 1000,
    easing: 'cubicBezier(0.545, 0.475, 0.145, 1)',
  })
  anime
    .timeline({
      duration: 1000,
      easing: 'cubicBezier(0.545, 0.475, 0.145, 1)',
      complete,
    })
    .add({
      targets: rest,
      opacity: 1,
      translateY: [100, 0],
      delay: anime.stagger(200),
    })
}

function animateSlideChange({ slides }, idx) {
  const before = slides.slice(0, idx)
  const active = slides.slice(idx, idx + 1)
  const after = slides.slice(idx + 1)
  const duration = 1200
  const easing = 'easeOutExpo'
  const translateX = getSlideX(idx) * -1
  before.forEach(({ current }, bIdx) => {
    anime({
      duration,
      easing,
      translateZ: 0,
      translateX:
        translateX - CARD_WIDTH - CARD_GAP - STACK_GAP + getTransformX(),
      targets: current,
      translateY: 0,
    })
  })
  anime({
    duration,
    easing,
    translateZ: 0,
    translateX,
    targets: active.map(({ current }) => current),
    translateY: -36,
  })
  anime({
    duration,
    easing,
    translateZ: 0,
    translateX,
    targets: after.map(({ current }) => current),
    // the reveal stagger can be interrupted and
    // gets stuck at non-zero if this is omitted
    translateY: 0,
  })
}

export default Carousel
