import * as React from 'react'
import { Link } from '../link'
import { Resource } from '../../data/types'
import { CoverImage } from '../image'

interface Props {
  resource: Resource
  className?: string
}

export default class ResourceCardMobileFeature extends React.Component<
  Props,
  {}
> {
  static defaultProps = {
    className: undefined,
  }

  public render() {
    const { resource } = this.props
    const { category, title, slug, header, excerpt } = resource
    const { src, alt } = header
    return (
      <Link to={slug} className="editorial-card editorial-card--mobile-feature">
        <div className="editorial-card__content child-my-0">
          <p className="editorial-card__category">{category}</p>
          <h3 className="editorial-card__title">{title}</h3>
          <div className="editorial-card__image">
            <CoverImage src={src} alt={alt} />
          </div>
          <p className="editorial-card__excerpt">{excerpt}</p>
        </div>
      </Link>
    )
  }
}
