import * as React from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import { Waypoint } from 'react-waypoint'
import { Link } from '../link'
import { Program } from './../../data/program'
import { PaginationButton } from '../button'
import { CoverImage } from '../image'
import { useDynamicTracking } from '../../hooks'

const SLIDE_TRANSITION_DUR = 1000
const SLIDE_AUTOPLAY_DELAY = 10000

const SLICK_CONFIG = {
  dots: false,
  arrows: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: SLIDE_AUTOPLAY_DELAY,
  pauseOnHover: true,
  speed: SLIDE_TRANSITION_DUR,
  slidesToShow: 1,
  centerMode: true,
  slidesToScroll: 1,
  variableWidth: true,
}

interface Props {
  id?: string
  title: string
  tagline?: string
  description: string
  programs: Program[]
}

type State = {
  index: number
}

const leadingZero = (n: number) => (n < 10 ? '0' + n : n)

const StyledSection = styled.section`
  padding: 56px 0 80px;

  @media screen and (min-width: 768px) {
    .container .container__row {
      grid-column: 2 / -2;
    }
  }
  @media screen and (min-width: 1024px) {
    padding: 84px 0 152px;

    .container .container__row {
      grid-column: 4 / -4;
    }
  }
`

export default class ProgramCarousel extends React.Component<Props, State> {
  state = { index: 0 }
  slider: any = React.createRef()

  componentDidMount() {
    this.pauseCarousel()
  }

  playCarousel = () => {
    const { current = {} } = this.slider
    current.slickPlay && current.slickPlay()
  }
  pauseCarousel = () => {
    const { current = {} } = this.slider
    current.slickPause && current.slickPause()
  }

  public render() {
    const { id, title, tagline, description, programs } = this.props
    const { index } = this.state
    const current = index + 1
    const total = programs.length
    const program = programs[index]
    const [onTrack] = useDynamicTracking({
      action: 'Navigated Programs Carousel',
      category: 'Home Page',
    })
    return (
      <StyledSection id={id} className="color-n95 bg-dark-navy">
        <CarouselHeader
          title={title}
          tagline={tagline}
          description={description}
        />
        <Waypoint onEnter={this.playCarousel} onLeave={this.pauseCarousel}>
          <div className="programs-carousel">
            <Slider
              {...SLICK_CONFIG}
              ref={this.slider}
              beforeChange={(_, index) => this.setState({ index })}
            >
              {programs.map(p => (
                <div key={p.name} className="programs-carousel__slide">
                  <div className="programs-carousel__slide-content">
                    <CoverImage
                      key={p.id}
                      alt={p.image.alt}
                      src={p.image.src}
                    />
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </Waypoint>
        <CarouselFooter
          current={current}
          total={total}
          onClickPrev={() => {
            const { current = {} } = this.slider
            current.slickPause && current.slickPause()
            current.slickPrev && current.slickPrev()
            onTrack({ label: 'Previous' })
          }}
          onClickNext={() => {
            const { current = {} } = this.slider
            current.slickPause && current.slickPause()
            current.slickNext && current.slickNext()
            onTrack({ label: 'Next' })
          }}
          name={program.name}
          description={program.description}
          slug={program.url}
        />
      </StyledSection>
    )
  }
}

const StyledCarouselHeader = styled.header`
  margin-bottom: 66px;
  text-align: center;

  .type-h2 {
    margin-left: auto;
    margin-right: auto;
    max-width: 450px;
  }

  @media screen and (min-width: 768px) {
    .type-h2 {
      max-width: 700px;
    }
  }
`

function CarouselHeader({ title, tagline, description }) {
  return (
    <StyledCarouselHeader>
      <div className="container container--grid">
        <div className="container__row child-my-0">
          <h2 className="type-sp-xxl">{title}</h2>
          {tagline ? (
            <p className="type-label-lg color-n85">{tagline}</p>
          ) : null}
          <p className="type-h2">{description}</p>
        </div>
      </div>
    </StyledCarouselHeader>
  )
}

const StyledCarouselControls = styled.div`
  margin: 16px 0 32px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  & > div:first-child {
    button + button {
      margin-left: 10px;
    }
  }

  @media screen and (max-width: 767px) {
    & > div:first-child {
      display: none;
    }
  }
`

const StyledCarouselContent = styled.div`
  h3 {
    margin: 0;
    font-weight: 700;
  }

  a {
    text-transform: uppercase;
    text-decoration: none;
  }

  p {
    margin: 16px 0 0;
  }

  & > div {
    margin-bottom: 32px;
  }

  @media screen and (min-width: 768px) {
    & > div {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 16px;
      margin-bottom: 16px;
    }

    h3 {
      max-width: 360px;
    }
    p {
      max-width: 320px;
    }
  }
`

function CarouselFooter({
  current,
  total,
  onClickPrev,
  onClickNext,
  name,
  description,
  slug,
}) {
  const [onTrack] = useDynamicTracking({
    action: 'Clicked Program in Carousel',
    category: 'Home Page',
  })
  return (
    <footer>
      <div className="container container--grid">
        <div className="container__row">
          <div
            className="progress-bar"
            style={{
              '--progress-bar': current / total,
              '--progress-bar-duration': SLIDE_TRANSITION_DUR + 'ms',
            }}
          />
          <StyledCarouselControls>
            <div>
              <PaginationButton
                direction="previous"
                appearance="secondary"
                aria-label="Previous"
                onClick={onClickPrev}
              />
              <PaginationButton
                appearance="secondary"
                aria-label="Next"
                onClick={onClickNext}
              />
            </div>
            <div className="type-mono-md color-n75">
              <span className="color-green">{leadingZero(current)}</span>
              <span>/</span>
              <span>{leadingZero(total)}</span>
            </div>
          </StyledCarouselControls>
          <StyledCarouselContent>
            <div>
              <h3 className="type-h1">{name}</h3>
              <p className="type-p-md color-n85">{description}</p>
            </div>
            {slug ? (
              <Link
                className="type-cta-link color-n95"
                to={slug}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => onTrack({ label: name })}
              >
                Learn more &rarr;
              </Link>
            ) : null}
          </StyledCarouselContent>
        </div>
      </div>
    </footer>
  )
}
