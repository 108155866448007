import * as React from 'react'
import { Link } from '../link'
import { IconButton } from '../button'
import { Twitter, LinkedIn, Facebook, Email, ExternalLink } from '../icons'
import cx from 'classnames'

import styles from './post-progress.module.scss'
import { StaticQuery, graphql } from 'gatsby'
import { useStaticTracking, useDynamicTracking } from '../../hooks'

interface Props {
  postTitle: string
  shareLink: string
  nextPostTitle: string
  nextPostLink: string
}

type State = {
  scrollDirection: 'UP' | 'DOWN'
  scrollPosition: number
  scrollProgress: number
}

export default class PostProgress extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      scrollPosition: 0,
      scrollDirection: 'UP',
      scrollProgress: 0,
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.updateScrollPosition)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.updateScrollPosition)
  }

  updateScrollPosition = e => {
    const { pageYOffset, innerHeight } = window
    const { scrollPosition } = this.state
    const dir = scrollPosition >= pageYOffset ? 'UP' : 'DOWN'

    const $article = document.querySelector('#content > article')
    if (!$article) return // badness has ensued
    const total = $article.scrollHeight
    const viewport = window.innerHeight
    const offset = $article.offsetTop
    const current = pageYOffset
    const progress = Math.max(
      Math.min(1, current / (total - viewport + offset)),
      0
    )
    this.setState({
      scrollDirection: pageYOffset < innerHeight ? 'DOWN' : dir,
      scrollPosition: pageYOffset,
      scrollProgress: progress,
    })
  }

  render() {
    const { scrollDirection, scrollProgress } = this.state
    const { postTitle, shareLink, nextPostTitle, nextPostLink } = this.props
    const [onTrackNext] = useStaticTracking({
      action: 'Click Next Post',
      category: 'Blog Post',
      label: nextPostTitle,
    })
    const [onTrackShare] = useDynamicTracking({
      label: postTitle,
      category: 'Blog Post',
    })
    return (
      <StaticQuery
        query={graphql`
          query MetadataURL {
            site {
              siteMetadata {
                url
              }
            }
          }
        `}
        render={({ site: { siteMetadata: query } }) => (
          <aside
            className={cx(styles.container, {
              // [styles.scrolling]: scrollDirection === 'DOWN',
            })}
          >
            <div className={styles.progress}>
              <div
                className={styles.progress__bar}
                style={{ transform: `scaleX(${scrollProgress})` }}
              />
            </div>
            <div className={cx(styles.content)}>
              <div
                className={cx(
                  styles.current,
                  'type-label-lg',
                  'overflow-ellipsis'
                )}
              >
                {postTitle}
              </div>
              <div
                className={cx(
                  styles.next,
                  'type-label-lg',
                  'overflow-ellipsis'
                )}
              >
                {nextPostTitle}
              </div>
              <Link
                to={nextPostLink}
                onClick={onTrackNext}
                className={cx(styles.link, 'type-cta-link')}
              >
                Next
              </Link>
              <ul className={styles.share}>
                <li>
                  <IconButton
                    as={Link}
                    onClick={() => onTrackShare({ action: 'Share by Email' })}
                    to={mailto(shareLink, postTitle, query.url)}
                  >
                    <Email />
                  </IconButton>
                </li>
                <li>
                  <IconButton
                    onClick={popup(linkedInIntent(shareLink, query.url), () =>
                      onTrackShare({ action: 'Share on LinkedIn' })
                    )}
                  >
                    <LinkedIn />
                  </IconButton>
                </li>
                <li>
                  <IconButton
                    onClick={popup(
                      twitterIntent(shareLink, postTitle, query.url),
                      () => onTrackShare({ action: 'Share on Twitter' })
                    )}
                  >
                    <Twitter />
                  </IconButton>
                </li>
                <li>
                  <IconButton
                    onClick={popup(facebookIntent(shareLink, query.url), () =>
                      onTrackShare({ action: 'Share on Facebook' })
                    )}
                  >
                    <Facebook />
                  </IconButton>
                </li>
              </ul>
            </div>
          </aside>
        )}
      />
    )
  }
}

const routeToFullUrl = (route: string, base: string) =>
  (base[base.length - 1] === '/' ? base.slice(0, -1) : base) + route

function twitterIntent(
  postRoute,
  postTitle = '',
  baseUrl = '/',
  intentUrl = 'https://twitter.com/intent/tweet'
) {
  const joined = routeToFullUrl(postRoute, baseUrl)
  const url = encodeURIComponent(joined)
  const text = encodeURIComponent(postTitle)
  return `${intentUrl}?text=${text}&url=${url}`
}

function mailto(
  postRoute,
  postTitle = '',
  baseUrl = '/',
  intentUrl = 'mailto:'
) {
  const joined = routeToFullUrl(postRoute, baseUrl)
  const url = encodeURIComponent(joined)
  const text = encodeURIComponent(postTitle)
  return `${intentUrl}?subject=${text}&body=${url}`
}

function facebookIntent(
  postRoute,
  baseUrl = '/',
  intentUrl = 'https://www.facebook.com/sharer.php'
) {
  const joined = routeToFullUrl(postRoute, baseUrl)
  const url = encodeURIComponent(joined)
  return `${intentUrl}?u=${url}`
}

function linkedInIntent(
  postRoute,
  baseUrl = '/',
  intentUrl = 'https://www.linkedin.com/shareArticle?mini=true'
) {
  const joined = routeToFullUrl(postRoute, baseUrl)
  const url = encodeURIComponent(joined)
  // first one is & not ? because there's always ?mini (above)
  return `${intentUrl}&url=${url}`
}

function popup(intent, cb = () => {}, width = 600, height = 500) {
  return () => {
    window.open(intent, '_blank', `width=${width},height=${height}`)
    cb && cb()
  }
}
