import * as React from 'react'
import cx from 'classnames'
import { GatsbyFluidImage, GatsbyFixedImage } from '../../data/types'
import GatsbyImage from 'gatsby-image'

interface Props {
  src?: string
  alt: string
  style?: any
  ratio?: number
  className?: string
  // use gatsby-image instead
  sizes?: GatsbyFluidImage
  fluid?: GatsbyFluidImage
  fixed?: GatsbyFixedImage
}

export function CoverImage({
  src,
  alt,
  ratio,
  style,
  className,
  ...props
}: Props) {
  return (
    <div
      className={cx('cover-image', className)}
      style={{
        ...(style ? style : {}),
        ...(ratio ? { paddingBottom: `${ratio * 100}%` } : {}),
      }}
    >
      {props.sizes || props.fluid || props.fixed ? (
        <GatsbyImage {...props} alt={alt} />
      ) : (
        <img
          key={src}
          ref={el => {
            if (el) {
              el.src = src ?? ''
            }
          }}
          alt={alt}
        />
      )}
    </div>
  )
}
