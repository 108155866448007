import * as React from 'react'
import { Link } from '../link'
import { CoverImage } from '../image'
import { ResponsiveImage } from '../../data/types'
import imageEmpty from '../../images/empty.png'

interface Props {
  /** Probably a category name */
  subtitle: string
  /** Probably a post name */
  title: string
  /** Where the card should link to */
  slug: string
  /** Image data (src and alt) */
  image: null | ResponsiveImage
  /** Either an HTML class or undefined */
  className?: string
  /** Event callback */
  onClick?: () => any
}

function PostCard({ subtitle, title, slug, image, onClick }: Props) {
  return (
    <Link to={slug} className="post-card" onClick={onClick}>
      <div className="post-card__image">
        <CoverImage
          className="cover-image--ratio"
          {...(image ? image : { src: imageEmpty, alt: 'None' })}
        />
      </div>
      <div className="post-card__content child-my-0">
        <p className="post-card__category type-mono-md">{subtitle}</p>
        <h3 className="post-card__title type-h6">{title}</h3>
      </div>
    </Link>
  )
}

PostCard.defaultProps = {
  className: undefined,
}

export default PostCard
