import * as React from 'react'
import { Link } from '../link'
import { Image } from '../../data/types'
import { CoverImage } from '../image'

interface Props {
  category: string
  title: string
  excerpt: string
  image: Image
  url: string
  className?: string
  onClick?: () => any
}

export default class ResourceCardLarge extends React.Component<Props, {}> {
  static defaultProps = {
    className: undefined,
  }

  public render() {
    const { category, title, url, image, excerpt, onClick } = this.props
    return (
      <Link
        onClick={onClick}
        to={url}
        className="editorial-card editorial-card--large"
      >
        <div className="editorial-card__content child-my-0">
          <p className="editorial-card__category">{category}</p>
          <h3 className="editorial-card__title">{title}</h3>
          <p className="editorial-card__excerpt">{excerpt}</p>
        </div>
        <div className="editorial-card__image">
          <CoverImage src={image.src} alt={image.alt} />
        </div>
      </Link>
    )
  }
}
