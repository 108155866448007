import * as React from 'react'
import shuffle from 'lodash/shuffle'
import cx from 'classnames'
import { Link } from '../link'
import { CoverImage } from '../image'
import { CoverImage as ClientCoverImage } from '../image/cover-image2'
import { FeatureLabel } from '../feature-label'
import styles from './resource-header.module.scss'
import { Image } from '../../data/types'
import { Resource } from '../../data/resource'

interface Props {
  resources: Resource[]
  className?: string
}

function ResourceHeader({ resources, className }: Props) {
  const [feature] = React.useMemo(() => resources.slice(0, 1), [resources])
  const stories = React.useMemo(() => {
    const shuffled = shuffle(resources.slice(1)).slice(0, 3)
    return shuffled
  }, [resources])

  return (
    <div className={cx(styles.header, className)}>
      <div className={cx(styles.container, 'container', 'container--grid')}>
        <h2 className={cx(styles.header__title, 'type-h-magic')}>
          <div>Resources</div>
        </h2>
        <ResourceHeaderFeature
          meta={feature.category.name}
          title={feature.title}
          excerpt={feature.description}
          slug={feature.url}
          image={feature.image}
        />
        <div className={cx(styles.header__stories)}>
          {stories.map(story => (
            <ResourceHeaderStory
              key={story.id}
              meta={story.category.name}
              title={story.title}
              slug={story.url}
              image={story.image}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

ResourceHeader.defaultProps = {
  className: undefined,
}

type FeatureProps = {
  meta: string
  title: string
  excerpt: string
  slug: string
  image: Image
}

type StoryProps = {
  meta: string
  title: string
  slug: string
  image: Image
}

function ResourceHeaderFeature({
  meta,
  title,
  excerpt,
  slug,
  image,
}: FeatureProps) {
  return (
    <Link to={slug} className={cx(styles.header__feature)}>
      <div className={cx(styles.header__featureImage)}>
        <CoverImage className={styles.coverImage} {...image} />
        <div className={cx(styles.header__featureImageLabel)}>
          <FeatureLabel text="New Resource" />
        </div>
      </div>
      <div className={cx(styles.header__featureContent)}>
        <div className={cx(styles.header__featureMeta, 'type-mono-md')}>
          {meta}
        </div>
        <h2 className={cx(styles.header__featureTitle, 'type-h2')}>{title}</h2>
        <p className={cx(styles.header__featureExcerpt, 'type-p-md')}>
          {excerpt}
        </p>
      </div>
    </Link>
  )
}

function ResourceHeaderStory({ meta, title, slug, image }: StoryProps) {
  return (
    <Link className={cx(styles.header__story)} to={slug}>
      <ClientCoverImage
        key={image.src}
        className={styles.coverImage}
        {...image}
      />
      <div>
        <span className={cx(styles.header__storyMeta, 'type-mono-md')}>
          {meta}
        </span>
        <h2 className={cx(styles.header__storyTitle, 'type-h5')}>{title}</h2>
      </div>
    </Link>
  )
}

export default ResourceHeader
