import * as React from 'react'
import { Link } from '../link'
import { Arrow } from '../icons'

interface Props {}

type ListItemProps = {
  name: string
  category: string
  slug: string
  onClick?: () => any
}

const Item = ({ name, category, slug, onClick }: ListItemProps) => (
  <li>
    <Link
      className="company-list__item"
      onClick={onClick}
      to={slug}
      target="_blank"
      rel="noopener noreferrer"
    >
      <span className="company-list__name">{name}</span>
      <span className="company-list__category">
        <span className="company-list__category-overflow">
          <span className="company-list__category-label">{category}</span>
          <span aria-hidden className="company-list__category-icon">
            <Arrow />
          </span>
        </span>
      </span>
    </Link>
  </li>
)

export default class CompanyList extends React.Component<Props, {}> {
  static defaultProps = {}
  static Item = Item

  public render() {
    const { children } = this.props
    return <ul className="company-list">{children}</ul>
  }
}
