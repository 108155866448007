import * as React from 'react'
import cx from 'classnames'
import { Link } from '../link'
import { CoverImage } from '../image'
import { ResponsiveImage } from '../../data/types'

import imageEmpty from '../../images/empty.png'
import styles from './post-card-full.module.scss'

interface Props {
  /** Probably a category name */
  subtitle: string
  /** Probably a post name */
  title: string
  /** Where the card should link to */
  slug: string
  /** Flip the text and content on non-mobile breakpoints */
  reverse?: boolean
  /** Image data (src and alt) */
  image: null | ResponsiveImage
  /** Post excerpt */
  description: string
  /** Either an HTML class or undefined */
  className?: string
}

function PostCardFull(props: Props) {
  return (
    <Link
      to={props.slug}
      className={cx(
        styles.container,
        {
          [styles.containerReverse]: props.reverse,
        },
        'child-my-0',
        props.className
      )}
    >
      <span className={cx(styles.container__subtitle, 'type-mono-md')}>
        {props.subtitle}
      </span>
      <h3 className={cx(styles.container__title, 'type-h3')}>
        <span>{props.title}</span>
      </h3>
      <CoverImage
        className={cx(styles.container__image)}
        {...(props.image ? props.image : { src: imageEmpty, alt: 'None' })}
      />
      <p className={cx(styles.container__description, 'type-p-md')}>
        {props.description}
      </p>
    </Link>
  )
}

PostCardFull.defaultProps = {
  className: undefined,
  reverse: false,
}

export default PostCardFull
