import * as React from 'react'
import cx from 'classnames'
import format from 'date-fns/format'
import { BlogPost } from '../../data/blog-post'
import { Link } from '../link'
import { CoverImage } from '../image'
import { FeatureLabel } from '../feature-label'
import styles from './blog-header.module.scss'
import { ResponsiveImage } from '../../data/types'
import imageEmpty from '../../images/empty.png'

interface Props {
  posts: BlogPost[]
  className?: string
}

const postToMetaLabel = (p: BlogPost) =>
  `${format(p.publishDate, 'MMMM d, yyyy')} | ${p.author.name}`

function BlogHeader(props: Props) {
  const [[feature], stories] = [
    props.posts.slice(0, 1),
    props.posts.slice(1, 4),
  ]

  return (
    <div className={cx(styles.header, props.className)}>
      <div className={cx(styles.container, 'container', 'container--grid')}>
        <h2 className={cx(styles.header__title, 'type-h-magic')}>
          <div>Recent Posts</div>
        </h2>
        <BlogHeaderFeature
          meta={postToMetaLabel(feature)}
          title={feature.title}
          excerpt={feature.description}
          slug={feature.slug}
          image={feature.hero}
        />
        <div className={cx(styles.header__stories)}>
          {stories.map(story => (
            <BlogHeaderStory
              key={story.slug}
              meta={postToMetaLabel(story)}
              title={story.title}
              slug={story.slug}
              image={story.hero}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

BlogHeader.defaultProps = {
  className: undefined,
}

type FeatureProps = {
  meta: string
  title: string
  excerpt: string
  slug: string
  image: null | ResponsiveImage
}

type StoryProps = {
  meta: string
  title: string
  slug: string
  image: null | ResponsiveImage
}

function BlogHeaderFeature(props: FeatureProps) {
  return (
    <div className={cx(styles.header__feature)}>
      <Link to={props.slug}>
        <div className={cx(styles.header__featureImage)}>
          <CoverImage
            className={styles.coverImage}
            {...(props.image ? props.image : { src: imageEmpty, alt: 'None' })}
          />
          <div className={cx(styles.header__featureImageLabel)}>
            <FeatureLabel
              text="New Feature"
              textColor="var(--color-dark-green)"
              backgroundColor="var(--color-mint)"
            />
          </div>
        </div>
        <div className={cx(styles.header__featureContent)}>
          <div>
            <div className={cx(styles.header__featureMeta, 'type-mono-md')}>
              {props.meta}
            </div>
            <h2 className={cx(styles.header__featureTitle, 'type-h2')}>
              {props.title}
            </h2>
          </div>
          <div>
            <p className={cx(styles.header__featureExcerpt, 'type-p-md')}>
              {props.excerpt}
            </p>
          </div>
        </div>
      </Link>
    </div>
  )
}

function BlogHeaderStory(props: StoryProps) {
  return (
    <Link className={cx(styles.header__story)} to={props.slug}>
      <CoverImage
        className={styles.coverImage}
        {...(props.image ? props.image : { src: imageEmpty, alt: 'None' })}
      />
      <div>
        <span className={cx(styles.header__storyMeta, 'type-mono-md')}>
          {props.meta}
        </span>
        <h2 className={cx(styles.header__storyTitle, 'type-h5')}>
          {props.title}
        </h2>
      </div>
    </Link>
  )
}

export default BlogHeader
