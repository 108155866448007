import * as React from 'react'
import classnames from 'classnames'
import { Company } from '../../data/types'
import { Link } from '../link'

interface Props {
  company: Company
  className?: string
  onClick?: () => any
}

export default class CompanyCard extends React.Component<Props, {}> {
  static defaultProps = {
    className: undefined,
  }

  public render() {
    const { company, className, onClick } = this.props
    const { category, name, slug, description, header, logo } = company
    return (
      <Link
        to={slug}
        onClick={onClick}
        target="_blank"
        rel="noopener noreferrer"
        className={classnames('company-card', className)}
      >
        <div
          className="company-card__header"
          role="img"
          aria-label={header.alt}
          style={{ backgroundImage: `url(${header.src})` }}
        />
        <div className="company-card__content">
          <div className="company-card__logo-side child-my-0">
            <p className="company-card__category">{category}</p>
            <div
              role="img"
              aria-label={logo.alt}
              className="company-card__logo"
              style={{ backgroundImage: `url(${logo.src})` }}
            />
          </div>

          <div className="company-card__text-side child-my-0">
            <h3 className="company-card__name">{name}</h3>
            <p className="company-card__description">{description}</p>
          </div>
        </div>
      </Link>
    )
  }
}
