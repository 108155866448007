import * as React from 'react'
import styled from 'styled-components'
import { balance } from './../../utils/balance'
import { Company } from '../../data/company'
import { CompanyList } from '../list'
import { Button } from '../button'
import { useDynamicTracking } from '../../hooks'

const StyledCompanyListsSection = styled.section`
  .type-h2 {
    margin-bottom: 36px;
  }

  .container {
    padding-top: 32px;
    padding-bottom: 56px;
    /* min-height: 80vh; */
    align-items: center;
  }

  @media screen and (min-width: 768px) {
    .container {
      padding-top: 80px;
      padding-bottom: 80px;
    }

    .type-h2 {
      margin-bottom: 60px;
    }
  }
  @media screen and (min-width: 1024px) {
    .container {
      padding-top: 120px;
      padding-bottom: 120px;
    }
  }

  & + & {
    .container {
      padding-top: 0 !important;
    }
  }
`

const StyledMobileList = styled.div`
  button {
    margin-top: 24px;
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
`

const StyledBalancedColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ count }) => count}, 1fr);
  grid-column-gap: 48px;

  @media screen and (max-width: 767px) {
    display: none;
  }
`

const StyledMediumOnly = styled.div`
  display: none;
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    display: block;
  }
`

const StyledLargeOnly = styled.div`
  display: none;
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    display: block;
  }
`

const StyledExtraLargePlus = styled.div`
  display: none;
  @media screen and (min-width: 1280px) {
    display: block;
  }
`

interface Props {
  title: string
  companies: Company[]
  id?: string
}

type State = {
  /** Limit to a specific number (0 = unlimited) */
  mobileListLimit: number
}

export default class CompanyListSection extends React.Component<Props, State> {
  state = {
    mobileListLimit: 7,
  }

  public render() {
    const { title, companies, id } = this.props
    const { mobileListLimit } = this.state
    return (
      <StyledCompanyListsSection id={id}>
        <div className="container container--grid">
          <div className="container__row">
            <h2 className="type-h2">{title}</h2>
            <MobileLimitedList
              limit={mobileListLimit}
              list={companies}
              onRequestShowAll={() => this.setState({ mobileListLimit: 0 })}
            />
            {/* CSS columns are rendering weird */}
            <StyledMediumOnly>
              <BalancedColumnLayout list={companies} columns={2} />
            </StyledMediumOnly>
            <StyledLargeOnly>
              <BalancedColumnLayout list={companies} columns={3} />
            </StyledLargeOnly>
            <StyledExtraLargePlus>
              <BalancedColumnLayout list={companies} columns={4} />
            </StyledExtraLargePlus>
          </div>
        </div>
      </StyledCompanyListsSection>
    )
  }
}

function MobileLimitedList({
  list,
  limit,
  onRequestShowAll,
}: {
  list: Company[]
  limit: number
  onRequestShowAll: (x: any) => any
}) {
  const subset = limit ? list.slice(0, limit) : list.slice(0)
  const [onTrack] = useDynamicTracking({
    action: 'Clicked Portfolio Company',
    category: 'Home Page',
  })
  return (
    <StyledMobileList>
      <CompanyList>
        {subset.map(c => (
          <CompanyList.Item
            key={c.slug}
            name={c.name}
            category={c.category}
            slug={c.slug}
            onClick={() => onTrack({ label: c.name })}
          />
        ))}
      </CompanyList>
      {limit && list.length > limit ? (
        <Button
          onClick={onRequestShowAll}
          appearance="secondary"
          size="small"
          fill
        >
          +
        </Button>
      ) : null}
    </StyledMobileList>
  )
}

function BalancedColumnLayout({
  list,
  columns,
}: {
  list: Company[]
  columns: number
}) {
  const balanced = balance(list, columns)
  const [onTrack] = useDynamicTracking({
    action: 'Clicked Portfolio Company',
    category: 'Home Page',
  })
  return (
    <StyledBalancedColumns count={columns}>
      {balanced.map(col => (
        <CompanyList key={col[0].name}>
          {col.map(c => (
            <CompanyList.Item
              key={c.slug}
              name={c.name}
              category={c.category}
              slug={c.slug}
              onClick={() => onTrack({ label: c.name })}
            />
          ))}
        </CompanyList>
      ))}
    </StyledBalancedColumns>
  )
}
