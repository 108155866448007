import * as React from 'react'
import { Link } from '../link'
import { Resource } from '../../data/resource'
import { CoverImage } from '../image'

interface Props {
  resource: Resource
  className?: string
  onClick?: () => any
}

export default class ResourceCard extends React.Component<Props, {}> {
  static defaultProps = {
    className: undefined,
  }

  public render() {
    const { resource, onClick } = this.props
    const { category, title, url, image } = resource
    const { src, alt } = image
    return (
      <Link
        to={url}
        onClick={onClick}
        className="editorial-card editorial-card--default"
      >
        <div className="editorial-card__image">
          <CoverImage ratio={0.85} src={src} alt={alt} />
        </div>
        <div className="editorial-card__content child-my-0">
          <p className="editorial-card__category">{category.name}</p>
          <h3 className="editorial-card__title">{title}</h3>
        </div>
      </Link>
    )
  }
}
