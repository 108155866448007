import * as React from 'react'
import { Link } from '../link'
import classnames from 'classnames'
import { Resource } from '../../data/resource'
import { CoverImage } from '../image'

interface Props {
  resource: Resource
  reverse?: boolean
  className?: string
}

export default class ResourceCardFull extends React.Component<Props, {}> {
  static defaultProps = {
    reverse: false,
    className: undefined,
  }

  public render() {
    const { resource, reverse, className } = this.props
    const { category, title, url, image, description } = resource
    return (
      <Link
        to={url}
        className={classnames(
          'editorial-card',
          'editorial-card--full',
          {
            '-x-reverse': reverse,
          },
          className
        )}
      >
        <div className="editorial-card__image">
          <CoverImage {...image} />
        </div>
        <div className="editorial-card__content child-my-0">
          <p className="editorial-card__category">{category}</p>
          <h3 className="editorial-card__title">{title}</h3>
          <p className="editorial-card__excerpt">{description}</p>
        </div>
      </Link>
    )
  }
}
