import * as React from 'react'
import cx from 'classnames'
import { Company } from '../../data/company'
import { CompanyCard } from '../card'
import { OurCompanies } from '.'
import { PaginationButton } from '../button'
import styles from './company-carousel.module.scss'
import { useDynamicTracking } from '../../hooks'

interface Props {
  title: string
  tagline: string
  companies: Company[]
  id: string
  className?: string
}

function CompanyCarousel(props: Props) {
  const refCarousel = React.useRef(null)
  const [onTrack] = useDynamicTracking({
    action: 'Navigated Company Carousel',
    category: 'Home Page',
  })
  return (
    <section id={props.id} className={cx(styles.section, props.className)}>
      <div className={cx(styles.container, 'container container--grid')}>
        <h2 className={cx(styles.section__heading, 'type-h-magic')}>
          <div>
            <div>
              Our
              <br />
              Companies
            </div>
          </div>
        </h2>
        <div className={cx(styles.section__content)}>
          <div>
            <p className={cx(styles.section__tagline, 'type-label-lg')}>
              {props.tagline}
            </p>
            <div className={cx(styles.section__description, 'type-h3')}>
              {props.title}
            </div>
          </div>
          <div className={cx(styles.section__controls)}>
            <PaginationButton
              onClick={() => {
                onTrack({ label: 'Previous' })
                refCarousel.current && refCarousel.current.onPrevious()
              }}
              direction="previous"
              aria-label="Previous"
            />
            <PaginationButton
              onClick={() => {
                onTrack({ label: 'Next' })
                refCarousel.current && refCarousel.current.onNext()
              }}
              aria-label="Next"
            />
          </div>
        </div>
      </div>

      <OurCompanies ref={refCarousel} companies={props.companies} />
      <MobileCarousel companies={props.companies} />
    </section>
  )
}

function MobileCarousel(props: any) {
  const [onTrack] = useDynamicTracking({
    action: 'Clicked Company in Carousel',
    category: 'Home Page',
  })
  return (
    <div className={styles.mobile}>
      <div className={cx(styles.mobile__cards)}>
        {props.companies.map(c => (
          <div key={c.name} className={cx(styles.mobile__card)}>
            <CompanyCard
              company={c}
              onClick={() => onTrack({ label: c.name })}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

CompanyCarousel.defaultProps = {
  className: undefined,
}

export default CompanyCarousel
