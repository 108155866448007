import * as React from 'react'
import cx from 'classnames'
import { ResponsiveImage } from '../../data/types'
import { Link } from '../link'
import { CoverImage } from '../image'
import { useDynamicTracking } from '../../hooks'
import { LinkItem } from '../../data/link'
import { TeamArrow } from '../icons'
import styles from './join-the-team.module.scss'

interface Props {
  heading: string
  photo: ResponsiveImage
  links: LinkItem[]
  className?: string
}

function JoinTheTeam(props: Props) {
  const [onTrackLink] = useDynamicTracking({
    action: 'Click link',
    category: 'Team Page',
  })
  return (
    <section className={cx(styles.section, props.className)}>
      <div className="container container--grid">
        <div className="container__row">
          <div className={cx(styles.cta)}>
            <div className={cx(styles.cta__content, 'child-my-0')}>
              <h2 className="type-h2">{props.heading}</h2>
              <div className={cx(styles.desktop)}>
                {(props.links || []).map(l => (
                  <Link
                    onClick={() => onTrackLink({ label: l.text })}
                    className={cx(styles.cta__link, 'type-p-lede')}
                    key={l.id}
                    to={l.location}
                  >
                    <span>{l.text}</span>
                    <TeamArrow />
                  </Link>
                ))}
              </div>
            </div>
            <CoverImage className={styles.coverImage} {...props.photo} />
            <div className={cx(styles.mobile)}>
              {(props.links || []).map(l => (
                <Link
                  onClick={() => onTrackLink({ label: l.text })}
                  className={cx(styles.cta__link, 'type-p-lede')}
                  key={l.id}
                  to={l.location}
                >
                  <span>{l.text}</span>
                  <TeamArrow />
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

JoinTheTeam.defaultProps = {
  className: undefined,
}

export default JoinTheTeam
