import * as React from 'react'
import format from 'date-fns/format'

interface Props {
  authorName?: string
  estimatedLength?: string
  publishDate?: Date
  title: string
}

export default function PostHeader({
  authorName,
  estimatedLength,
  publishDate,
  title,
}: Props) {
  return (
    <header className="post-header">
      <div className="container">
        <div className="post-header__content">
          <div className="post-header__time-n-title">
            {!publishDate ? null : (
              <time
                className="display-block type-mono-md"
                dateTime={format(publishDate, 'yyyy-MM-dd')}
              >
                {format(publishDate, 'MMMM d, yyyy')}
              </time>
            )}
            <h1 className="type-h-xxl">{title}</h1>
          </div>
          <div className="post-header__byline type-label-md">
            <address className="author">{authorName}</address>
            <span>{estimatedLength}</span>
          </div>
        </div>
      </div>
    </header>
  )
}
