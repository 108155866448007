import * as React from 'react'
import { Flower } from '../icons'

interface Props {
  children: React.ReactNode
}

export default function PostHeader({ children }: Props) {
  return (
    <footer className="post-footer">
      <div className="container container--grid">
        <div className="container__row">
          <div className="wysiwyg">
            <div>
              <Flower />
              <hr />
              <div className="child-my-0">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
