import * as React from 'react'
import shuffle from 'lodash/shuffle'
import { Resource } from '../../data/resource'
import {
  EditorialCard,
  EditorialCardLarge,
  EditorialCardSmall,
  EditorialCardReverse,
} from '../card'
import { Button } from '../button'
import { Link } from '../link'
import { useDynamicTracking } from '../../hooks'

interface Props {
  title: string
  resources: Resource[]
}

export default function ResourcesSection({ title, resources }: Props) {
  const [feature] = resources.slice(0, 1)
  const shuffled = shuffle(resources.slice(1))
  const smalls = shuffled.slice(0, 2)
  const rest = shuffled.slice(2)
  const [onTrack] = useDynamicTracking({
    action: 'Clicked Resource',
    category: 'Featured Resources Section',
  })
  return (
    <section className="resources-section">
      {/* Mobile layout */}
      <div className="resources-section__mobile">
        <div className="container container--grid">
          <h2 className="resources-section__heading type-h-magic">
            <div>{title}</div>
          </h2>
          <div>
            {[feature, ...smalls].map(r => (
              <EditorialCardReverse
                key={r.url}
                resource={r}
                onClick={() => onTrack({ label: r.title })}
              />
            ))}
          </div>
        </div>
        <div className="container">
          <Button
            arrow
            fill
            as={Link}
            to="/resources"
            onClick={() => onTrack({ label: 'View All' })}
          >
            View All Resources
          </Button>
        </div>
      </div>

      {/* Tablet+ layout */}
      <div className="resources-section__desktop">
        <div className="container container--grid">
          <div className="container__row">
            <div className="resources-section__top">
              <h2 className="resources-section__heading type-h-magic">
                <div>{title}</div>
              </h2>
              <div className="resources-section__feature">
                <EditorialCardLarge
                  title={feature.title}
                  category={feature.category.name}
                  excerpt={feature.description}
                  image={feature.image}
                  url={feature.url}
                  onClick={() => onTrack({ label: feature.title })}
                />
              </div>
              <div className="resources-section__smalls">
                {smalls.map(r => (
                  <EditorialCardSmall
                    key={r.url}
                    resource={r}
                    onClick={() => onTrack({ label: r.title })}
                  />
                ))}
                <Button
                  arrow
                  fill
                  as={Link}
                  to="/resources"
                  onClick={() => onTrack({ label: 'View All' })}
                >
                  View All Resources
                </Button>
              </div>
            </div>
            <div className="resources-section__bottom">
              {rest.map(r => (
                <EditorialCard
                  key={r.url}
                  resource={r}
                  onClick={() => onTrack({ label: r.title })}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
